import { Transition } from "@headlessui/react";
import axios from "axios";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { IoMdDownload } from "react-icons/io";
import { IoCopy } from "react-icons/io5";
import { MdChevronRight } from "react-icons/md";
import { SiTicktick } from "react-icons/si";
import * as XLSX from "xlsx";
import Loader from "../../components/loader/Loader";

const API_URL = "https://api.checkproxy.vn";
// const API_URL = "http://localhost:4001";

const OUTPUT_FORMAT = [
  {
    name: "ip:port:user:pass",
    value: (proxy) => {
      return proxy;
    },
  },
  {
    name: "user:pass:ip:port",
    value: (proxy) => {
      const [f1, f2, f3, f4] = proxy.split(":");
      return `${f3}:${f4}:${f1}:${f2}`;
    },
  },
  {
    name: "ip:port",
    value: (proxy) => {
      const [f1, f2, f3, f4] = proxy.split(":");
      return `${f1}:${f2}`;
    },
  },
  {
    name: "user:pass",
    value: (proxy) => {
      const [f1, f2, f3, f4] = proxy.split(":");
      return `${f3}:${f4}`;
    },
  },
  {
    name: "ip",
    value: (proxy) => {
      const [f1, f2, f3, f4] = proxy.split(":");
      return `${f1}`;
    },
  },
  {
    name: "user:pass@ip:port",
    value: (proxy) => {
      const [f1, f2, f3, f4] = proxy.split(":");
      return `${f3}:${f4}@${f1}:${f2}`;
    },
  },
  {
    name: "ip:port@user:pass",
    value: (proxy) => {
      const [f1, f2, f3, f4] = proxy.split(":");
      return `${f1}:${f2}@${f3}:${f4}`;
    },
  },
  {
    name: "http://user:pass@ip:port",
    value: (proxy) => {
      const [f1, f2, f3, f4] = proxy.split(":");
      return `http://${f3}:${f4}@${f1}:${f2}`;
    },
  },
  {
    name: "http://ip:port@user:pass",
    value: (proxy) => {
      const [f1, f2, f3, f4] = proxy.split(":");
      return `http://${f1}:${f2}@${f3}:${f4}`;
    },
  },
];

const INPUT_FORMAT = [
  {
    name: "ip:port:user:pass",
    value: "0:1:2:3",
  },
  {
    name: "http://user:pass@ip:port",
    value: "http://2:3@0:1",
  },
  {
    name: "http://ip:port@user:pass",
    value: "http://0:1@2:3",
  },
  {
    name: "user:pass@ip:port",
    value: "2:3@0:1",
  },
  {
    name: "ip:port@user:pass",
    value: "0:1@2:3",
  },
  {
    name: "user:pass:ip:port",
    value: "2:3:0:1",
  },
];

const INPUT_PROTOCOL = [
  {
    name: "http",
    value: "http",
  },
  {
    name: "socks5",
    value: "socks5",
  },
];

const SERVER_CHECK = [
  {
    name: "mặc định (lấy public ip)",
    value: "ip-api.com/json",
  },
  {
    name: "google.com",
    value: "google.com",
  },
  {
    name: "x.com",
    value: "x.com",
  },
  {
    name: "facebook.com",
    value: "facebook.com",
  },
  {
    name: "discord.com",
    value: "discord.com",
  },
  {
    name: "youtube.com",
    value: "youtube.com",
  },
  {
    name: "domain khác",
    value: "ohterDomain",
  },
];

const INPUT_RES_TIMEOUT = [
  {
    name: "30s",
    value: 30000,
  },
  {
    name: "20s",
    value: 20000,
  },
  {
    name: "15s",
    value: 15000,
  },
  {
    name: "10s",
    value: 10000,
  },
  {
    name: "5s",
    value: 5000,
  },
];

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [liveProxys, setliveProxys] = useState([]);
  const [dieProxies, setDieProxies] = useState([]);
  const [resLiveProxys, setResLiveProxys] = useState([]);
  const [resdieProxies, setResDieProxies] = useState([]);
  const [maxTimeRes, setMaxTimeRes] = useState(0);
  const [minTimeRes, setMinTimeRes] = useState(0);

  const [inputProxysText, setInputProxysText] = useState("");

  const [clientIp, setClientIp] = useState("00.00.00.00");
  const [country, setCountry] = useState("__");
  const [isLiveCopied, setLiveCopy] = useState(false);
  const [isDieCopied, setDieCopy] = useState(false);
  const [indexHpFormat, setIndexHpFormat] = useState(0);
  const [indexInputFormat, setIndexInputFormat] = useState(0);
  const [indexInputProtocol, setIndexInputProtocol] = useState(0);
  const [indexResTimeout, setIndexResTimeout] = useState(0);
  const [indexServerCheck, setIndexServerCheck] = useState(0);
  const [isEnabledButton, setIsEnabledButton] = useState(false);

  // other domain
  const [isOtherDomain, setIsOtherDomain] = useState(false);
  const [otherDomain, setOtherDomain] = useState("");

  useEffect(() => {
    const getIpAddress = async () => {
      const url = `${API_URL}/get-client-ip`;
      const res = await axios.get(url);
      setClientIp(res?.data?.clientIp);
      setCountry(res?.data?.country);
    };
    getIpAddress();
  }, []);

  useEffect(() => {
    if (isLiveCopied) {
      const timer = setTimeout(() => {
        setLiveCopy(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isLiveCopied]);

  useEffect(() => {
    if (isDieCopied) {
      const timer = setTimeout(() => {
        setDieCopy(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [isDieCopied]);

  useEffect(() => {
    if (SERVER_CHECK[indexServerCheck].value === "ohterDomain") {
      setIsOtherDomain(true);
      return;
    }
    setIsOtherDomain(false);
  }, [indexServerCheck]);

  useEffect(() => {
    if (resLiveProxys.length > 0) {
      // get format
      const handleFormat = OUTPUT_FORMAT[indexHpFormat].value;

      const newLiveProxys = resLiveProxys.map((e) => {
        return {
          ...e,
          hostPort: handleFormat(e.hostPort),
        };
      });
      setliveProxys(newLiveProxys);
    } else {
      setliveProxys([]);
    }
  }, [indexHpFormat, resLiveProxys]);

  useEffect(() => {
    if (resdieProxies.length > 0) {
      // get format
      const handleFormat = OUTPUT_FORMAT[indexHpFormat].value;

      const newdieProxies = resdieProxies.map((e) => {
        return {
          ...e,
          hostPort: handleFormat(e.hostPort),
        };
      });
      setDieProxies(newdieProxies);
    } else {
      setDieProxies([]);
    }
  }, [indexHpFormat, resdieProxies]);
  useEffect(() => {
    if (inputProxysText.length != 0) setIsEnabledButton(true);
    else setIsEnabledButton(false);
  }, [inputProxysText]);

  const fetchCheckProxy = async () => {
    setIsLoading(true);
    const iProxys = inputProxysText.split("\n");
    const serverCheck = isOtherDomain
      ? otherDomain
      : SERVER_CHECK[indexServerCheck].value;

    const url = `${API_URL}/check-proxies?timeout=${INPUT_RES_TIMEOUT[indexResTimeout].value}&checkDomain=${serverCheck}&protocol=${INPUT_PROTOCOL[indexInputProtocol].value}`;
    const res = await axios.post(url, {
      listProxys: fomatinputProxysText(iProxys),
    });

    const liveProxys = res.data.data?.liveProxies;
    const newDieProxies = res.data.data?.dieProxies;
    const maxTimeRes = res.data.data?.maxTimeRes;
    const minTimeRes = res.data.data?.minTimeRes;

    setIsLoading(false);
    setResLiveProxys([...liveProxys]);
    setResDieProxies([...newDieProxies]);
    setMaxTimeRes(maxTimeRes / 1000);
    setMinTimeRes(minTimeRes / 1000);
  };

  const handleDownload = (proxys) => {
    const header = [
      "STT",
      "HOST PORT",
      "ADDRESS",
      "GEO",
      "TIMEZONE",
      "CITY",
      "RES TIME (ms)",
    ];
    const excelData = [
      header,
      ...proxys.map((proxy, i) => {
        const { hostPort, clientIp, country, timezone, city, elapsedTime } =
          proxy;
        return [
          i + 1,
          hostPort,
          clientIp,
          country,
          timezone,
          city,
          elapsedTime,
        ];
      }),
    ];

    // create file Excel
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Live proxy");

    // create name file
    const currentDate = new Date();
    const fileName = `valid_urls_${currentDate
      .toLocaleString()
      .replace(/[/,:]/g, "_")}.xlsx`;

    // download file Excel
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, fileName);
  };

  const handleLiveCopy = (event) => {
    event.preventDefault();
    const urlString = liveProxys.map((e) => e.hostPort).join("\n");

    navigator.clipboard
      .writeText(urlString)
      .then(() => {
        setLiveCopy(true);
        console.log("Đã sao chép các URL vào clipboard!");
      })
      .catch((error) => {
        console.error("Không thể sao chép vào clipboard:", error);
      });
  };

  const handleDieCopy = (event) => {
    event.preventDefault();
    const urlString = dieProxies.map((e) => e.hostPort).join("\n");

    navigator.clipboard
      .writeText(urlString)
      .then(() => {
        setDieCopy(true);
        console.log("Đã sao chép các URL vào clipboard!");
      })
      .catch((error) => {
        console.error("Không thể sao chép vào clipboard:", error);
      });
  };

  const fomatinputProxysText = (inputProxysText) => {
    const inputFormat = INPUT_FORMAT[indexInputFormat].value;
    switch (inputFormat) {
      case "0:1:2:3":
        return inputProxysText.map((e) => {
          const l = e.split(":");
          return l[0] + ":" + l[1] + ":" + l[2] + ":" + l[3];
        });
      case "http://2:3@0:1":
        return inputProxysText.map((e) => {
          const [userpass, url] = e.replace(/^http?:\/\//, "")?.split("@");

          if (!userpass || !url) return "";
          const [user, pass] = userpass?.split(":");
          const [host, port] = url?.split(":");
          return host + ":" + port + ":" + user + ":" + pass;
        });
      case "http://0:1@2:3":
        return inputProxysText.map((e) => {
          const [url, userpass] = e.replace(/^http?:\/\//, "")?.split("@");

          if (!userpass || !url) return "";
          const [user, pass] = userpass?.split(":");
          const [host, port] = url?.split(":");
          return host + ":" + port + ":" + user + ":" + pass;
        });
      case "2:3:0:1":
        return inputProxysText.map((e) => {
          const l = e.split(":");
          return l[2] + ":" + l[3] + ":" + l[0] + ":" + l[1];
        });
      case "2:3@0:1":
        return inputProxysText.map((e) => {
          const [userpass, url] = e?.split("@");
          if (!userpass || !url) return "";
          const [user, pass] = userpass?.split(":");
          const [host, port] = url?.split(":");
          return host + ":" + port + ":" + user + ":" + pass;
        });
      case "0:1@2:3":
        return inputProxysText.map((e) => {
          const [url, userpass] = e?.split("@");
          if (!userpass || !url) return "";
          const [user, pass] = userpass?.split(":");
          const [host, port] = url?.split(":");
          return host + ":" + port + ":" + user + ":" + pass;
        });
      default:
    }
  };

  if (isLoading) return <Loader />;

  if (isLoading) return <Loader />;
  return (
    <>
      <Transition
        appear={true}
        enter="transition-all ease-in-out duration-500 delay-[100ms]"
        enterFrom="opacity-0 translate-y-6"
        show={true}
        enterTo="opacity-100 translate-y-0"
        leave="transition-all ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className=" w-full bg-white flex flex-col ">
          <div className="relative py-24  overflow-hidden bg-white lg:mt-0 isolate  ">
            <div className="flex mx-4  lg:mx-10 flex-col items-center ">
              <h1
                className=" flex justify-center text-3xl font-bold animate-text-gradient bg-gradient-to-r from-[#152238] via-[#90e0ef] to-black
    bg-[200%_auto] bg-clip-text text-transparent"
              >
                CHECK PROXY
              </h1>
              <h1
                className="font-bold  animate-text-gradient bg-gradient-to-r from-[#152238] via-[#90e0ef] to-black
    bg-[200%_auto] bg-clip-text text-transparent flex text-sm "
              >
                (Kiểm tra proxy)
              </h1>
              <h1
                className="font-bold  animate-text-gradient bg-gradient-to-r from-[#152238] via-[#90e0ef] to-black
    bg-[200%_auto] bg-clip-text text-transparent flex text-sm "
              >
                Địa chỉ IP của bạn: {clientIp} - quốc gia: {country}
              </h1>
              <button
                className=" hidden lg:flex w-45 items-center justify-center gradient-button text-gray-300 font-bold
                py-4 px-4 rounded-lg mt-4 disabled:bg-gradient-to-r disabled:from-gray-500 disabled:to-gray-500
                 disabled:text-gray-200 disabled:cursor-not-allowed  disabled:hover:bg-gradient-to-r disabled:hover:from-gray-500 disabled:hover:to-gray-500 disabled:hover:text-gray-200"
                onClick={fetchCheckProxy}
                disabled={!isEnabledButton}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="rocket-launch"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="svg-inline--fa fa-rocket-launch h-4 w-4 inline-block mr-2"
                >
                  <path
                    fill="currentColor"
                    d="M197.9 253.9c12.9 6.4 24.7 14.8 35 25.2s18.8 22.2 25.2 35c105.8-30 156.7-79.5 181.5-126c25-46.8 27.3-97.6 22-137.7c-40.1-5.3-90.9-3-137.7 22c-46.5 24.8-96 75.8-126 181.5zM384 312.1l0 82.2c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9l0-114.7c0-22.6-9-44.3-25-60.3s-37.7-25-60.3-25L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.2 0C281.7-3.8 408.8-8.5 483.9 5.3c11.6 2.1 20.7 11.2 22.8 22.8c13.8 75.1 9.1 202.2-122.7 284zM28.3 511.9c-16 .4-28.6-12.2-28.2-28.2C1 446 7.7 379.7 42 345.5c34.4-34.4 90.1-34.4 124.5 0s34.4 90.1 0 124.5C132.3 504.3 66 511 28.3 511.9zm50.2-64.5c12.8-.7 31.2-3.7 41.3-13.7c11.4-11.4 11.4-30 0-41.4s-30-11.4-41.4 0c-10.1 10.1-13 28.5-13.7 41.3c-.5 8 5.9 14.3 13.9 13.9zM328 144a40 40 0 1 1 80 0 40 40 0 1 1 -80 0z"
                    className=""
                  ></path>
                </svg>
                Kiểm Tra Proxy
              </button>
              <div className="mt-2 lg:grid lg:grid-cols-11 flex flex-col w-full">
                <div className="   lg:col-span-5  bg-none">
                  <label
                    htmlFor="url-count"
                    className="mr-1 flex lg:block text-gray-400 mb-2"
                  >
                    Số lượng proxy kiểm tra:{" "}
                    {inputProxysText.length === 0
                      ? 0
                      : inputProxysText.split("\n").length}
                  </label>

                  <div className="shadow-[5px_5px_5px] shadow-color-gray-500 shadow-opacity-50 rounded-lg">
                    <div className="flex h-auto items-center justify-between  px-3 py-2  bg-main_color rounded-t-lg">
                      <div className="justify-start w-auto h-auto ">
                        {" "}
                        <label className=" text-white text-sm hidden md:inline">
                          Định dạng kiểm tra: &nbsp;
                        </label>
                        <select
                          id="format-output"
                          onChange={(e) => setIndexInputFormat(e.target.value)}
                          className="inline-block bg-main_color border text-sm rounded-lg border-gray-600 placeholder-gray-400 text-white outline-none"
                        >
                          <option defaultValue value={indexInputFormat}>
                            {INPUT_FORMAT[indexInputFormat].name}
                          </option>
                          {INPUT_FORMAT.map(
                            (format, index) =>
                              index !== +indexInputFormat && (
                                <option key={index} value={index}>
                                  {format.name}
                                </option>
                              )
                          )}
                        </select>
                      </div>
                      <div className="justify-start w-auto h-auto ">
                        {" "}
                        <label className=" text-white text-sm hidden md:inline">
                          Giao thức: &nbsp;
                        </label>
                        <select
                          id="format-output"
                          onChange={(e) =>
                            setIndexInputProtocol(e.target.value)
                          }
                          className="inline-block bg-main_color border text-sm rounded-lg border-gray-600 placeholder-gray-400 text-white outline-none"
                        >
                          <option defaultValue value={indexInputProtocol}>
                            {INPUT_PROTOCOL[indexInputProtocol].name}
                          </option>
                          {INPUT_PROTOCOL.map(
                            (format, index) =>
                              index !== +indexInputProtocol && (
                                <option key={index} value={index}>
                                  {format.name}
                                </option>
                              )
                          )}
                        </select>
                      </div>
                      <div
                        id="tooltip-fullscreen"
                        role="tooltip"
                        className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                      >
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </div>
                    <div className="flex h-auto items-center justify-between  px-3 py-2  bg-main_color ">
                      <div className="justify-start w-auto h-auto ">
                        {" "}
                        <label className=" text-white text-sm hidden md:inline">
                          Kiểm tra với máy chủ: &nbsp;
                        </label>
                        {isOtherDomain ? (
                          <input
                            type="text"
                            id="domain"
                            className="inline-block w-40 bg-main_color border border-gray-600 text-white text-sm rounded-lg placeholder-gray-400"
                            placeholder="exampleDomain.com"
                            onChange={(e) => setOtherDomain(e.target.value)}
                            onInput={otherDomain}
                            required
                          />
                        ) : (
                          <select
                            id="format-output"
                            onChange={(e) =>
                              setIndexServerCheck(e.target.value)
                            }
                            className="inline-block bg-main_color border text-sm rounded-lg border-gray-600 placeholder-gray-400 text-white outline-none"
                          >
                            <option defaultValue value={indexInputFormat}>
                              {SERVER_CHECK[indexServerCheck].name}
                            </option>
                            {SERVER_CHECK.map(
                              (format, index) =>
                                index !== +indexServerCheck && (
                                  <option key={index} value={index}>
                                    {format.name}
                                  </option>
                                )
                            )}
                          </select>
                        )}
                      </div>

                      <div className="justify-start w-auto h-auto ">
                        {" "}
                        <label className=" text-white text-sm hidden md:inline">
                          Tgian phản hồi tối đa: &nbsp;
                        </label>
                        <select
                          id="format-output"
                          onChange={(e) => setIndexResTimeout(e.target.value)}
                          className=" inline-block  bg-main_color justify-end w-auto h-auto border text-sm rounded-lg border-gray-600 placeholder-gray-400 text-white outline-none"
                        >
                          <option defaultValue value={indexResTimeout}>
                            {INPUT_RES_TIMEOUT[indexResTimeout].name}
                          </option>
                          {INPUT_RES_TIMEOUT.map(
                            (format, index) =>
                              index !== +indexResTimeout && (
                                <option key={index} value={index}>
                                  {format.name}
                                </option>
                              )
                          )}
                        </select>
                      </div>

                      <div
                        id="tooltip-fullscreen"
                        role="tooltip"
                        className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                      >
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </div>

                    <textarea
                      style={{
                        whiteSpace: "pre-wrap",
                        color: "text-gray-900",
                        overflow: "auto", // Enables scrolling
                        scrollbarWidth: "none", // For Firefox
                        msOverflowStyle: "none", // For Internet Explorer and Edge
                        resize: "none", // Prevents resizing
                      }}
                      id="url-field"
                      className="placeholder-gray-400 h-[40vh] flex-col  overflow-y-auto  border-none bg-gray-300 flex w-full py-2 px-3 text-gray-700  rounded-b-lg text-sm"
                      placeholder={`ip:port:username:password\nip:port:username:password\nip:port:username:password\nip:port:username:password\nip:port:username:password`}
                      value={inputProxysText}
                      onChange={(e) => setInputProxysText(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="flex  w-full items-center justify-center">
                  <button className="border-blue-900 hidden lg:col-span-1 bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200 lg:flex items-center justify-center rounded-full p-2 text-3xl text-black transition duration-200 hover:cursor-pointer dark:text-black">
                    <MdChevronRight className=" md:border-black" />
                  </button>
                  <button
                    className="lg:hidden flex w-45 items-center justify-center gradient-button text-gray-300 font-bold
                      py-4 px-4 rounded-lg mt-4 disabled:bg-gradient-to-r disabled:from-gray-500 disabled:to-gray-500
                       disabled:text-gray-200 disabled:cursor-not-allowed  disabled:hover:bg-gradient-to-r disabled:hover:from-gray-500 disabled:hover:to-gray-500 disabled:hover:text-gray-200"
                    onClick={fetchCheckProxy}
                    disabled={!isEnabledButton}
                  >
                    Kiểm Tra Proxy
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="rocket-launch"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="svg-inline--fa fa-rocket-launch h-4 w-4 inline-block ml-2"
                    >
                      <path
                        fill="currentColor"
                        d="M197.9 253.9c12.9 6.4 24.7 14.8 35 25.2s18.8 22.2 25.2 35c105.8-30 156.7-79.5 181.5-126c25-46.8 27.3-97.6 22-137.7c-40.1-5.3-90.9-3-137.7 22c-46.5 24.8-96 75.8-126 181.5zM384 312.1l0 82.2c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9l0-114.7c0-22.6-9-44.3-25-60.3s-37.7-25-60.3-25L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.2 0C281.7-3.8 408.8-8.5 483.9 5.3c11.6 2.1 20.7 11.2 22.8 22.8c13.8 75.1 9.1 202.2-122.7 284zM28.3 511.9c-16 .4-28.6-12.2-28.2-28.2C1 446 7.7 379.7 42 345.5c34.4-34.4 90.1-34.4 124.5 0s34.4 90.1 0 124.5C132.3 504.3 66 511 28.3 511.9zm50.2-64.5c12.8-.7 31.2-3.7 41.3-13.7c11.4-11.4 11.4-30 0-41.4s-30-11.4-41.4 0c-10.1 10.1-13 28.5-13.7 41.3c-.5 8 5.9 14.3 13.9 13.9zM328 144a40 40 0 1 1 80 0 40 40 0 1 1 -80 0z"
                        className=""
                      ></path>
                    </svg>
                  </button>
                </div>

                <div className="flex flex-col lg:col-span-5 ">
                  <div className="mr-1 flex lg:block text-blue-500  mb-2">
                    <span>Proxy sống: {liveProxys.length}</span>

                    <span className="ml-2 text-[#DB7093]">
                      Proxy chết: {dieProxies.length}
                    </span>
                  </div>

                  <div className="w-full  shadow-[5px_5px_5px] shadow-color-gray-500 shadow-opacity-50  mb-4 border  rounded-lg  bg-gray-700 border-gray-600">
                    <div className="flex w-auto items-center justify-between  px-3 py-2 border-b border-gray-600">
                      <button
                        type="button"
                        disabled={liveProxys.length === 0}
                        onTouchEnd={handleLiveCopy}
                        onClick={handleLiveCopy}
                        data-tooltip-target="tooltip-fullscreen"
                        className=" text-gray-500 flex flex-row rounded cursor-pointer  hover:text-gray-900  dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        {!isLiveCopied ? (
                          <IoCopy
                            size={20}
                            className="text-blue-500 flex  cursor-pointer"
                          />
                        ) : (
                          <SiTicktick
                            size={20}
                            className="text-blue-500  flex  cursor-pointer"
                          />
                        )}
                        {!isLiveCopied ? (
                          <span className="text-sm text-blue-500  flex">
                            Copy proxy sống
                          </span>
                        ) : (
                          <span className="text-sm text-blue-500  flex">
                            Copied
                          </span>
                        )}
                      </button>
                      <button
                        type="button"
                        disabled={dieProxies.length === 0}
                        onTouchEnd={handleDieCopy}
                        onClick={handleDieCopy}
                        data-tooltip-target="tooltip-fullscreen"
                        className="ml-[5px] text-gray-500 flex flex-row rounded cursor-pointer  hover:text-gray-900  dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        {!isDieCopied ? (
                          <IoCopy
                            size={20}
                            className="text-[#DB7093] flex  cursor-pointer"
                          />
                        ) : (
                          <SiTicktick
                            size={20}
                            className="text-[#DB7093] flex  cursor-pointer"
                          />
                        )}
                        {!isDieCopied ? (
                          <span className="text-sm text-[#DB7093] flex">
                            Copy proxy chết
                          </span>
                        ) : (
                          <span className="text-sm text-[#DB7093] flex">
                            Copied
                          </span>
                        )}
                      </button>

                      <button
                        disabled={liveProxys.length === 0}
                        type="button"
                        onClick={() => handleDownload(liveProxys)}
                        data-tooltip-target="tooltip-fullscreen"
                        className="p-2  text-gray-500 flex flex-row rounded cursor-pointer sm:ms-auto justify-end hover:text-gray-900  dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        <IoMdDownload
                          size={20}
                          className="text-white flex justify-end cursor-pointer"
                        />

                        <span className="text-sm text-white flex">
                          Download excel
                        </span>
                      </button>
                      <div
                        id="tooltip-fullscreen"
                        role="tooltip"
                        className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                      >
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </div>
                    <div className="flex w-auto items-center justify-between  px-3 py-2 border-b border-gray-600">
                      <div className="justify-start w-auto h-auto ">
                        <label className=" text-white text-sm hidden md:inline">
                          Định dạng sử dụng: &nbsp;
                        </label>
                        <select
                          id="format-output"
                          onChange={(e) => setIndexHpFormat(e.target.value)}
                          className="inline w-auto h-auto border  text-sm rounded-lg  bg-gray-700 border-gray-600 placeholder-gray-400 text-white outline-none"
                        >
                          <option defaultValue value={indexHpFormat}>
                            {OUTPUT_FORMAT[indexHpFormat].name}
                          </option>
                          {OUTPUT_FORMAT.map(
                            (format, index) =>
                              index !== indexHpFormat && (
                                <option key={index} value={index}>
                                  {format.name}
                                </option>
                              )
                          )}
                        </select>
                      </div>

                      <label className="text-sm text-white flex ml-5">
                        Thời gian phản hồi:&nbsp;
                        {minTimeRes == maxTimeRes
                          ? `${minTimeRes}s`
                          : `${minTimeRes}s - ${maxTimeRes}s`}
                      </label>

                      <div
                        id="tooltip-fullscreen"
                        role="tooltip"
                        className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                      >
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </div>

                    <div className="px-4 h-[30vh] py-2 rounded-b-lg bg-gray-800">
                      <div className="relative bg-transparent">
                        <span className="absolute left-1/6 bottom-2/4 z-[1] text-5xl text-[#90e0ef] transform rotate-[300deg] select-none opacity-20">
                          checkproxy.vn
                        </span>

                        <div
                          style={{
                            overflow: "auto", // Enables scrolling
                            scrollbarWidth: "none", // For Firefox
                            msOverflowStyle: "none", // For Internet Explorer and Edge
                            resize: "none", // Prevents resizing
                          }}
                          className="block overflow-y-auto h-[29vh] w-full px-0 text-sm border-gray-800 bg-gray-800 focus:ring-0 text-white placeholder-gray-400"
                        >
                          {liveProxys.map((proxy, index) => {
                            const [f1, f2, f3, f4] = proxy.hostPort.split(":");
                            const publicIp = proxy.publicIp || "không kiểm tra"; // Assuming `publicIP` is a property in each proxy object
                            const proxyType = proxy.type;

                            return (
                              <div
                                key={index}
                                className="flex items-center py-1"
                              >
                                {/* Public IP Display */}
                                <span
                                  className="mr-2 text-[10px] text-gray-400 opacity-60 select-none !min-w-[115px]"
                                  style={{
                                    minWidth: "80px",
                                    textAlign: "left",
                                  }}
                                >
                                  public IP: {publicIp}
                                </span>
                                <span
                                  className="mr-2 text-[10px] text-gray-400 opacity-60 select-none !min-w-[115px]"
                                  style={{
                                    minWidth: "80px",
                                    textAlign: "left",
                                  }}
                                >
                                  loại proxy: {proxyType}
                                </span>

                                {/* Proxy Host and Port Display */}
                                <div>
                                  {f1 && (
                                    <span style={{ color: "#90e0ef" }}>
                                      {f1}
                                    </span>
                                  )}
                                  {f2 && (
                                    <span
                                      style={{ color: "#ab90af" }}
                                    >{`:${f2}`}</span>
                                  )}
                                  {f3 && (
                                    <span
                                      style={{ color: "orange" }}
                                    >{`:${f3}`}</span>
                                  )}
                                  {f4 && (
                                    <span
                                      style={{ color: "#E0FFFF" }}
                                    >{`:${f4}`}</span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <hr class="border-blue-500"></hr>
                    <div className="px-4 h-[10vh] py-2 rounded-b-lg bg-gray-800">
                      <div className="relative bg-transparent">
                        <span className="absolute left-1/6 bottom-2/4 z-[1] text-5xl text-[#90e0ef] transform rotate-[300deg] select-none opacity-20">
                          checkproxy.vn
                        </span>

                        <div
                          style={{
                            overflow: "auto", // Enables scrolling
                            scrollbarWidth: "none", // For Firefox
                            msOverflowStyle: "none", // For Internet Explorer and Edge
                            resize: "none", // Prevents resizing
                          }}
                          className="block overflow-y-auto h-[9vh] w-full px-0 text-sm border-gray-800 bg-gray-800 focus:ring-0 text-white placeholder-gray-400"
                        >
                          {dieProxies.map((proxy, index) => {
                            const [f1, f2, f3, f4] = proxy.hostPort.split(":");
                            const publicIp = proxy.publicIp || "không kiểm tra"; // Assuming `publicIP` is a property in each proxy object
                            const proxyType = proxy.type;

                            return (
                              <div
                                key={index}
                                className="flex items-center py-1"
                              >
                                {/* Public IP Display */}
                                <span
                                  className="mr-2 text-[10px] text-gray-400 opacity-60 select-none !min-w-[115px]"
                                  style={{
                                    minWidth: "80px",
                                    textAlign: "left",
                                  }}
                                >
                                  public IP: {publicIp}
                                </span>
                                <span
                                  className="mr-2 text-[10px] text-gray-400 opacity-60 select-none !min-w-[115px]"
                                  style={{
                                    minWidth: "80px",
                                    textAlign: "left",
                                  }}
                                >
                                  loại proxy: {proxyType}
                                </span>

                                {/* Proxy Host and Port Display */}
                                <div>
                                  {f1 && (
                                    <span style={{ color: "#DB7093" }}>
                                      {f1}
                                    </span>
                                  )}
                                  {f2 && (
                                    <span
                                      style={{ color: "#DB7093" }}
                                    >{`:${f2}`}</span>
                                  )}
                                  {f3 && (
                                    <span
                                      style={{ color: "#DB7093" }}
                                    >{`:${f3}`}</span>
                                  )}
                                  {f4 && (
                                    <span
                                      style={{ color: "#DB7093" }}
                                    >{`:${f4}`}</span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default Home;
